<template>
  <NuxtLink :to="props.to" class="nav-item">
    <Icon v-if="props.icon" :name="props.icon" size="24" class="nav-item-icon" />
    <span class="nav-item-label">
      {{ label }}
      <UIBadge v-if="props.badge" :value="props.badge" class="nav-item-badge" />
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'

const props = defineProps({
  to: { type: [String, Object] as PropType<RouteLocationRaw>, default: undefined },
  label: { type: String, required: true },
  icon: { type: String, default: undefined },
  badge: { type: Number, default: undefined },
})
</script>
