<template>
  <div class="layout-default">
    <aside>
      <div class="aside-container">
        <SvgLogo class="logo" />
        <AppNavigationBar class="main-nav-bar" />
      </div>
    </aside>
    <main>
      <slot />
    </main>
  </div>
</template>
