<template>
  <nav class="nav-bar">
    <!-- <AppNavigationBarItem to="/" label="Home" icon="fluent:home-24-regular" /> -->
    <AppNavigationBarItem v-if="isLoggedIn" class="nav-item" label="Log out" icon="fluent:sign-out-24-regular" @click.prevent="handleLogout" />
    <AppNavigationBarItem v-else to="/login" label="Login" />
  </nav>
</template>

<script setup lang="ts">
const { isLoggedIn, logout } = useAuth()
const Dialog = useDialog()

async function handleLogout() {
  if (await Dialog.confirm('Are you sure you want to log out?')) logout()
}
</script>
